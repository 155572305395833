export const environment = {
  production: false,
  log: true,
  sin2api:
    "https://api-intense.stage.sardegnaturismocloud.it/ras_api/ost?_format=json",
  graphqlUrl:
    "https://api-intense.stage.sardegnaturismocloud.it/api/v1.0/graphql?",
  oauth2: {
    clientId: "129e2d34-a6fc-45f3-8a6d-068199905ca1",
    clientSecret: "123",
    scope: "ost_editor",
    tokenUrl: "https://api-intense.stage.sardegnaturismocloud.it/oauth/token",
    logoutUrl: "https://turismoattivo.stage.sardegnaturismocloud.it/login",
  },
  sessionUrl: "https://api-intense.stage.sardegnaturismocloud.it/session/token",
  userDetailUrl:
    "https://api-intense.stage.sardegnaturismocloud.it/user/${userID}?_format=json",
  authEndPoint: {
    responseUrl:
      "https://api-intense.stage.sardegnaturismocloud.it/api/v1.0/idm_login/",
    idmUrl:
      "https://accessmanager.demo.sardegnait.it/access-manager/oauth/authorize?response_type=code&client_id=stage.turismoattivo.sardegnaturismo.it&redirect_uri=https://turismoattivo.stage.sardegnaturismocloud.it/sso_return&scope=read",
  },
  helpUrl: 'https://api.turismoattivo.sardegnaturismo.it/sites/default/files/2020-07/Manuale_Utente_SIN2_2.pdf',
  supportUrl: 'https://api.turismoattivo.sardegnaturismo.it/contact',
  forumUrl: 'https://groups.google.com/forum/#!forum/operatori-sin2',
  howtoSearchUrl: 'https://api.turismoattivo.sardegnaturismo.it/sin2-howto-ricerca-schede-intense',
  howtoEditUrl: 'https://api.turismoattivo.sardegnaturismo.it/sin2-howto-creazione-modifica-schede-intense',
  howtoPublishUrl: 'https://api.turismoattivo.sardegnaturismo.it/sin2-howto-pubblicazione-schede-intense',
  howtoTranslateUrl: 'https://api.turismoattivo.sardegnaturismo.it/sin2-howto-gestione-traduzioni'
};
